import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { TbLockExclamation } from 'react-icons/tb';

import * as config from '../config.js';
import { ActionCard } from '../controls/cards';

export const Forbidden = ({ showMessage }) => {

  return (
    <Container>
      <Row className="mt-4">
        <Col className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">

          {showMessage &&
            <ActionCard
              title="Authentication required"
              text="We need to verify your identity"
              icon={<TbLockExclamation />}
              label="Authenticate"
              callback={config.LoginUrlParameter}
            />
          }

        </Col>
      </Row>

    </Container>);

}