import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Alert,
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Form,
  Image,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  BiChurch,
  BiSolidCreditCardAlt,
  BiSolidPhoneCall,
} from 'react-icons/bi';
import {
  BsArrowLeftRight,
  BsCaretRight,
  BsCartPlus,
  BsEnvelopeAt,
  BsHouseHeart,
} from 'react-icons/bs';
import {
  FaBabyCarriage,
  FaBalanceScale,
  FaCar,
  FaHandsHelping,
} from 'react-icons/fa';
import {
  MdRefresh,
  MdSchool,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';

// import { QuoterCard } from '../app/quoters';
// import { InfoCard } from '../controls/cards';
import { Hero } from '../controls/hero';
import { ReportingDataContext } from '../libs/contexts';
//import { getLead } from '../libs/demo';
import { asCurrency } from '../libs/i18n';
import { AlternativeCard } from './alternatives';
import { FaceValueCard } from './facevalue';
import {
  CostObjection,
  ProductObjection,
  TimingObjection,
  ValueObjection,
} from './objections';
import { SalesTips } from './sales-tips';
import { Statistics } from './statistics';
import { Timeline } from './timeline';
import { TriggerCard } from './triggers';
import { UpsellCard } from './upsell';

export const LeadFeatureDisabled = () => {

    return (
        <>
            <div className="text-center">
                <Badge>not available in demo</Badge>
            </div>
        </>
    )
}

export const Lead = () => {

    let { id } = useParams();
    const { reportingData } = useContext(ReportingDataContext);
    let [leadInfo, setLeadInfo] = useState(false);


    useEffect(() => {

        if (undefined !== reportingData && undefined !== reportingData.rows && id) {

            for (let row of reportingData.rows) {
                if (id === row.quote) {
                    setLeadInfo(row);
                }
            }

        }

    }, [id, reportingData]);

    return (<>


        {leadInfo ?
            <>
                <LeadEssentials lead={leadInfo} />
            </>
            :
            <></>}

        <Container fluid>
            <Row>
                <Col className="col-md-6 col-lg-5 col-xxl-3">
                    {leadInfo &&
                        <>
                            <SummaryCard lead={leadInfo} />
                            <FaceValueCard lead={leadInfo} />
                            <TriggerCard lead={leadInfo} />
                        </>
                    }
                </Col>
                <Col className="px-0 mx-0">
                    <Container fluid>
                        <Row>
                            <Col className="col-md-6 col-xxl-5">
                                <UpsellCard lead={leadInfo} />
                            </Col>
                            <Col className="col-md-6 col-xxl-5">
                                <AlternativeCard lead={leadInfo} />
                            </Col>
                            <Col>
                                <NeedsAdvisor />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="col-md-12 col-xxl-10">
                                <SalesObjections lead={leadInfo} />
                            </Col>
                            <Col>
                                <SalesTips />
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Timeline lead={leadInfo} />
                            </Col>
                        </Row> */}
                    </Container>
                </Col>
            </Row>
        </Container>
    </>);

}
const LeadEssentials = ({ lead }) => {

    const [premium, setPremium] = useState("");

    useEffect(() => {

        if (lead.premium) {
            let newPremium = lead.premium;
            if (isNaN(newPremium)) {
                newPremium = newPremium.replace("$", "");
                newPremium = parseFloat(newPremium);
            }
            newPremium = asCurrency({ amount: newPremium }) + " per month";
            setPremium(newPremium);
        }

    }, [lead]);

    return (
        <>
            <Hero title={lead.first_name + " " + lead.last_name} text={asCurrency({ amount: lead.facevalue, dropPennies: true }) + (lead.term ? " / " + lead.term + " years / " : " / ") + premium + " / " + (['yes', 'Yes'].includes(lead.rider) ? ' with rider' : 'no rider')} />
        </>

    )

}

const NeedsAdvisor = () => {

    return (
        <Card>
            <Card.Header>Reminder</Card.Header>
            <Card.Body>
                Remember to confirm needs analysis if proposing a different coverage
            </Card.Body>
        </Card>
    )

}

const SalesObjections = ({ lead }) => {

    return (
        <Card className="mb-2">
            <Card.Header className="bg-danger text-white">Objections handling</Card.Header>
            <Card.Body>
                {lead &&
                    <Tabs
                        defaultActiveKey="price"
                        id="objections-tab"
                        className="mb-3"
                    >
                        <Tab eventKey="price" title="Price">
                            <CostObjection lead={lead} />
                        </Tab>
                        <Tab eventKey="value" title="Value">
                            <ValueObjection lead={lead} />
                        </Tab>
                        <Tab eventKey="timing" title="Timing">
                            <TimingObjection lead={lead} />
                        </Tab>
                        <Tab eventKey="product" title="Product">
                            <ProductObjection lead={lead} />
                        </Tab>
                    </Tabs>
                }
            </Card.Body>
        </Card>
    )

}

const SummaryCard = ({ lead }) => {

    return (
        <Card className="mb-2">
            <Card.Body>
                <ListGroup variant="flush" className="mb-2">
                    <ListGroup.Item>
                        <h5>{lead.phone}
                            <Button variant="outline-primary" className="float-end"><BiSolidPhoneCall /></Button>
                        </h5>

                    </ListGroup.Item>
                    <ListGroup.Item>
                        <h5>{lead.email}

                            <Button variant="outline-primary" className="float-end"><BsEnvelopeAt /></Button>
                        </h5>


                    </ListGroup.Item>
                </ListGroup>
                <p>
                    {"F" === lead.gender &&
                        <Badge>Female</Badge>
                    }
                    {"M" === lead.gender &&
                        <Badge>Male</Badge>
                    }&nbsp;
                    <Badge>{lead.current_age} years old</Badge>
                    {/* <Badge>smoker</Badge> */}
                </p>
                <p className="text-muted">
                    {lead.address1} <br />
                    {lead.city} {lead.province} <br />
                    {lead.postal_code}
                </p>
            </Card.Body>
        </Card>

    )

}
