import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Alert,
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  ListGroup,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  BiChurch,
  BiSolidCreditCardAlt,
  BiSolidPhoneCall,
} from 'react-icons/bi';
import {
  BsArrowLeftRight,
  BsCaretRight,
  BsCartPlus,
  BsEnvelopeAt,
  BsHouseHeart,
} from 'react-icons/bs';
import {
  FaBabyCarriage,
  FaBalanceScale,
  FaCar,
  FaHandsHelping,
} from 'react-icons/fa';
import {
  MdRefresh,
  MdSchool,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { QuoterCard } from '../app/quoters';
import { InfoCard } from '../controls/cards';
import { Hero } from '../controls/hero';
import { getLead } from '../libs/api';
import { asCurrency } from '../libs/i18n';
import { AlternativeCard } from './alternatives';
import {
  CostObjection,
  ProductObjection,
  TimingObjection,
  ValueObjection,
} from './objections';
import { SalesAdvisor } from './sales-tips';
import { Statistics } from './statistics';
import { Timeline } from './timeline';
import { UpsellCard } from './upsell';

export const FaceValueCard = ({ lead }) => {


    return (
        <Card className="mb-2">
            <Card.Header>
                Face value statistics
            </Card.Header>
            <Card.Body>
                {lead &&
                    <Statistics lead={lead} />
                }
            </Card.Body>
        </Card>
    )

}
